import { acceptHMRUpdate, defineStore } from 'pinia'

export const usePrice = defineStore('variantPrice', {
  state: () => ({
    price: null,
  }),

  getters: {
    variantPrice: (state) => state.price,
  },
  actions: {
    set(price) {
      this.price = price
    }
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePrice, import.meta.hot))
}
