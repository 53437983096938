import { useCustomization, actionTypes } from '@/hooks/useCustomization'
import { useToast } from "vue-toastification";
import { useFavorites } from '@/stores/favorites'
import { usePrice } from '@/stores/price'

export const useEnhancer = () => {
  const { actions } = useCustomization()
  const toast = useToast();
  const storeFavorites = useFavorites()
  const storePrice = usePrice()

  return {
    actions,
    actionTypes,
    toast,
    storeFavorites,
    storePrice
  }
}