<script>
/*global FaceView, */
/*eslint no-undef: "error"*/
import { defineComponent, watch, ref, nextTick, computed } from "vue";
import { Layout } from "@/components";
import { GDialog } from "gitart-vue-dialog";
import { scriptsLoader } from "@/utils/scripts-loader";
import Step1 from "@/assets/svg/step1.svg";
import Step2 from "@/assets/svg/step2.svg";
import Step3 from "@/assets/svg/step3.svg";
import Measure1 from "@/assets/svg/measure1.svg";
import Measure2 from "@/assets/svg/measure2.svg";
import Measure3 from "@/assets/svg/measure3.svg";
import Hand from "@/assets/svg/hand.svg";
import NoGlasses from "@/assets/svg/no-glasses.svg";
import Upload from "@/assets/svg/upload.svg";
import GreenLine from "@/assets/svg/green-line.svg";
import RedLine from "@/assets/svg/red-line.svg";
import SwitchCamera from "@/assets/svg/switch-camera-icon.svg";
import { Howl, Howler } from "howler";
import { shadowBody } from "@/utils/build";
import { copyToClipboard } from "quasar";

export default defineComponent({
  name: "App",
  components: {
    Layout,
    GDialog,
    Step1,
    Step2,
    Step3,
    Hand,
    NoGlasses,
    Upload,
    GreenLine,
    RedLine,
    Measure1,
    Measure2,
    Measure3,
    SwitchCamera
  },
  emits: ["measurementFinish"],
  setup(props, { emit }) {
    let step = ref(1);
    const camera = ref(null);
    const tryOn = ref(null);
    const inputImage = ref(null);
    const getSize = ref(null);
    const countdown = ref(3);
    const counting = ref(false);
    const startMeasure = ref(false);
    const measuring = ref(false);
    const measureDone = ref(false);
    let rightPosition = ref(false);
    let timeoutStart = ref(null);
    let timeoutStopCamera = ref(null);
    let interval = ref(null);
    let frontUrl = ref(null);
    let leftUrl = ref(null);
    let rightUrl = ref(null);
    let leftTempleWidth = ref(null);
    let rightTempleWidth = ref(null);
    let nextBtn = ref("次へ");
    let dialog1 = ref(false);
    let dialog2 = ref(false);
    let dialog3 = ref(false);
    let dialog4 = ref(false);
    let dialog5 = ref(false);
    let dialog6 = ref(false);
    let dialog7 = ref(false);
    let dialog8 = ref(false);
    let errDialog = ref(false);
    let notiDialog = ref(false);
    let isValidPhoto = ref(false);
    let loading = ref(false);
    let showSwitchBtn = ref(false);
    const isScriptsLoaded = ref(false);
    let pd = ref(0);
    let faceWidth = ref(0);
    let templeLens = ref(0);
    let showIndicator = ref(true);
    let tempImg = ref(null);
    let View = ref(null);
    const sound = new Howl({
      src: [
        "https://production-glasson.s3.ap-northeast-1.amazonaws.com/hello-o/camera-shutter.mp3"
      ]
    });
    // const beep = new Howl({ src: ['https://production-glasson.s3.ap-northeast-1.amazonaws.com/hello-o/beep.mp3']});
    const beep = new Howl({
      src: [
        "https://production-glasson.s3.ap-northeast-1.amazonaws.com/hello-o/beep.mp3"
      ],
      volume: 0.2
    });
    // *: Define use

    // *: Define computed
    const isIpadOS = computed(
      () =>
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
    );
    const isMobile = computed(
      () =>
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) || isIpadOS.value
    );
    const isTablet = computed(
      () =>
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          navigator.userAgent.toLowerCase()
        ) || isIpadOS.value
    );
    // *: Define watch & method

    function doVariant() {
      const data = {
        pdSize: pd.value,
        faceWidth: faceWidth.value,
        leftTempleWidth: leftTempleWidth.value,
        rightTempleWidth: rightTempleWidth.value,
        userFrontPhoto: frontUrl.value,
        userLeftPhoto: leftUrl.value,
        userRightPhoto: rightUrl.value
      };
      emit("measurementFinish", data);
    }

    const onNextClick = (value) => {
      if (loading.value) return;
      if (!isScriptsLoaded.value) return;
      if (value === 5 && !isValidPhoto.value && measureDone.value) {
        loading.value = true;
        uploadImage(tempImg.value, "front");
        return;
      }
      if (value === 6 && !isValidPhoto.value && measureDone.value) {
        loading.value = true;
        uploadImage(tempImg.value, "right");
        return;
      }
      if (value === 7 && !isValidPhoto.value && measureDone.value) {
        loading.value = true;
        uploadImage(tempImg.value, "left");
        return;
      }
      if (value === 1) {
        step.value = 2;
        dialog2.value = true;
        dialog1.value = false;
        nextBtn.value = "了承して次へ";
      } else if (value === 2) {
        step.value = 3;
        dialog3.value = true;
        dialog2.value = false;
        nextBtn.value = "同意して次へ";
      } else if (value === 3) {
        step.value = 4;
        dialog4.value = true;
        dialog3.value = false;
        nextBtn.value = "同意して測定をはじめる";
      } else if (value === 4) {
        step.value = 51;
        // dialog5.value = true;
        // dialog4.value = false;
        nextBtn.value = "測定画面へ";
      } else if (value === 51) {
        step.value = 5;
        dialog5.value = true;
        dialog4.value = false;
        nextBtn.value = "測定する";
        // showSwitchBtn.value = true;
      } else if (value === 5 && !measureDone.value) {
        startMeasure.value = true;
        loading.value = true;
        // showSwitchBtn.value = false;
      } else if (value === 5 && measureDone.value) {
        step.value = 61;
        // dialog6.value = true;
        // dialog5.value = false;
        // isValidPhoto.value = false;
        // measureDone.value = false;
        nextBtn.value = "測定画面へ";
      } else if (value === 61) {
        step.value = 6;
        dialog6.value = true;
        dialog5.value = false;
        isValidPhoto.value = false;
        measureDone.value = false;
        nextBtn.value = "測定する";
        // showSwitchBtn.value = true;
      } else if (value === 6 && !measureDone.value) {
        startMeasure.value = true;
        // showSwitchBtn.value = false;
        loading.value = true;
      } else if (value === 6 && measureDone.value) {
        step.value = 71;
        // dialog7.value = true;
        // dialog6.value = false;
        // isValidPhoto.value = false;
        // measureDone.value = false;
        nextBtn.value = "測定画面へ";
      } else if (value === 71) {
        step.value = 7;
        dialog7.value = true;
        dialog6.value = false;
        isValidPhoto.value = false;
        measureDone.value = false;
        nextBtn.value = "測定する";
        // showSwitchBtn.value = true;
      } else if (value === 7 && !measureDone.value) {
        startMeasure.value = true;
        loading.value = true;
        // showSwitchBtn.value = false;
      } else if (value === 7 && measureDone.value) {
        step.value = 8;
        dialog8.value = true;
        dialog7.value = false;
        isValidPhoto.value = false;
        nextBtn.value = "オーダーフローに戻る";
      } else {
        doVariant();
        // step.value = 1;
        // dialog1.value = true;
        // dialog8.value = false;
        // nextBtn.value = "次へ";
      }
    };

    const startCountdown = () => {
      counting.value = true;
      beep.play();
      interval.value = setInterval(function () {
        beep.play();
        countdown.value -= 1;
        if (countdown.value === 0) {
          beep.stop();
          sound.play();
          clearInterval(interval.value);
          startMeasure.value = false;
          counting.value = false;
          measuring.value = false;
          tryOn.value.toBlob((blob) => {
            tempImg.value = blob;
            nextBtn.value = "結果を測定する";
            measureDone.value = true;
            countdown.value = 3;
            loading.value = false;
          });
        }
      }, 1400);
    };

    const stopMeasurement = () => {
      measureDone.value = false;
      measuring.value = false;
      counting.value = false;
      countdown.value = 3;
      clearTimeout(timeoutStart.value);
      clearTimeout(timeoutStopCamera.value);
      clearInterval(interval.value);
    };

    const isCenterFace = (pos) => {
      return (
        (tryOn.value.width * 30) / 100 < pos.x &&
        pos.x < (tryOn.value.width * 70) / 100 &&
        (tryOn.value.height * 20) / 100 < pos.y &&
        pos.y < (tryOn.value.height * 80) / 100
      );
    };

    function setBestDPIForCanvas(canvas) {
      const w = canvas.width;
      const h = canvas.height;
      canvas.width = w * window.devicePixelRatio;
      canvas.height = h * window.devicePixelRatio;
      canvas.style.width = w + "px";
      canvas.style.height = h + "px";
    }

    function showSwitch() {
      showSwitchBtn.value = true;
    }

    async function startCamera() {
      if (!tryOn.value || !camera.value) return;
      loading.value = true;
      tryOn.value.width = camera.value.clientWidth;
      // tryOn.value.height = camera.value.clientHeight;
      tryOn.value.height = tryOn.value.width;
      if (!isMobile.value) {
        tryOn.value.height = window.innerHeight - 80;
      }
      // Set best DPI for canvas
      await new Promise((resolve) => setTimeout(resolve), 100);
      setBestDPIForCanvas(tryOn.value);
      // View.value = let tryOnView = new FaceView(
      //   "tryon_canvas",
      //   camera.value.getRootNode()
      // );
      // let tryOnView = View.value
      let tryOnView = new FaceView(
        "tryon_canvas",
        camera.value.getRootNode()
        // shadowBody()?.getRootNode()
      );
      View.value = tryOnView;
      if (dialog5.value) {
        tryOnView
          .start(
            (isFrontFace, facePosition) => {
              if (!startMeasure.value) return;
              rightPosition.value = isFrontFace && isCenterFace(facePosition);
              if (rightPosition.value && !measuring.value) {
                if (startMeasure.value) {
                  measuring.value = true;
                  timeoutStart.value = setTimeout(startCountdown, 1000);
                  const requestAnimationFrame = () => {
                    tryOnView.stop();
                    tryOnView = null;
                    showSwitchBtn.value = false;
                  };
                  timeoutStopCamera.value = setTimeout(
                    requestAnimationFrame,
                    5000
                  );
                }
              }
              if (!rightPosition.value) {
                stopMeasurement();
              }
            },
            () => {} // side event
          )
          .then(
            function () {
              loading.value = false;
              showIndicator.value = false;
              setTimeout(showSwitch, 1000);
            },
            function () {}
          );
      } else if (dialog6.value || dialog7.value) {
        tryOnView
          .start(
            () => {},
            (isFrontFace, facePosition) => {
              if (!startMeasure.value) return;
              rightPosition.value = isFrontFace && isCenterFace(facePosition);
              if (rightPosition.value && !measuring.value) {
                if (startMeasure.value) {
                  measuring.value = true;
                  timeoutStart.value = setTimeout(startCountdown, 1000);
                  const requestAnimationFrame = () => {
                    tryOnView.stop();
                    tryOnView = null;
                    showSwitchBtn.value = false;
                  };
                  timeoutStopCamera.value = setTimeout(
                    requestAnimationFrame,
                    5000
                  );
                }
              }
              if (!rightPosition.value) {
                stopMeasurement();
              }
            }
          )
          .then(
            function () {
              loading.value = false;
              showIndicator.value = false;
              setTimeout(showSwitch, 1000);
            },
            function (err) {}
          );
      }
    }

    function getFront() {
      var formData = new FormData();
      formData.append("url_image", frontUrl.value);
      fetch(`https://helloo-measure.sk-global.io/api/front_api`, {
        method: "POST",
        body: formData
      }).then(async (res) => {
        const data = await res.json();
        if (data.result.pupil_distance) {
          pd.value = Math.round(data.result.pupil_distance);
          faceWidth.value = Math.round(data.result.head_distance);
          loading.value = false;
          isValidPhoto.value = true;
          onNextClick(5);
        } else {
          // startCamera();
          errDialog.value = true;
        }
        showIndicator.value = false;
      });
    }
    function getSide(side) {
      var formData = new FormData();
      if (side === "right") {
        formData.append("url_image", rightUrl.value);
      } else {
        formData.append("url_image", leftUrl.value);
      }
      fetch(`https://helloo-measure.sk-global.io/api/side_api`, {
        method: "POST",
        body: formData
      }).then(async (res) => {
        const data = await res.json();
        if (data.result.ear_distance && side === "right") {
          templeLens.value = Math.round(data.result.ear_distance);
          rightTempleWidth.value = Math.round(data.result.ear_distance);
          isValidPhoto.value = true;
          loading.value = false;
          onNextClick(6);
        } else if (data.result.ear_distance && side === "left") {
          templeLens.value = Math.round(data.result.ear_distance);
          leftTempleWidth.value = Math.round(data.result.ear_distance);
          isValidPhoto.value = true;
          loading.value = false;
          onNextClick(7);
        } else {
          // startCamera();
          errDialog.value = true;
        }
        loading.value = false;
        showIndicator.value = false;
      });
    }

    function uploadImage(blob, type) {
      if (!blob) return;
      showIndicator.value = true;
      var formData = new FormData();
      formData.append("file", blob, `${type}.png`);
      fetch(
        `https://glasson.sk-global.biz/api/v2/Attachments/production-glasson/upload`,
        {
          method: "POST",
          body: formData
        }
      ).then(async (res) => {
        const data = await res.json();
        const path = data.result.files.file[0].providerResponse.location;
        if (type === "front") {
          frontUrl.value = path;
          getFront();
        } else if (type === "right") {
          rightUrl.value = path;
          getSide("right");
        } else if (type === "left") {
          leftUrl.value = path;
          getSide("left");
        }
      });
    }

    function clickLink(vl) {
      if (vl === "left" && leftUrl.value) {
        window.open(`${leftUrl.value}`);
      }
      if (vl === "right" && rightUrl.value) {
        window.open(`${rightUrl.value}`);
      }
      if (vl === "front" && frontUrl.value) {
        window.open(`${frontUrl.value}`);
      }
    }

    function clickCopy() {
      const text = `PD \n${pd.value}mm \n顔幅 \n${faceWidth.value}mm \n左テンプル長 \n${templeLens.value}mm \n右テンプル長 \n${rightTempleWidth.value}mm \n顔幅画像 \n${frontUrl.value} \n左テンプル画像 \n${leftUrl.value} \n右テンプル画像 \n${rightUrl.value}`;
      // const text = `PD \n${pd.value}mm \n顔幅 \n${faceWidth.value}mm \n左テンプル長 \n${templeLens.value}mm \n右テンプル長 \n${rightTempleWidth.value}mm \n左テンプル画像 \n${leftUrl.value} \n右テンプル画像 \n${rightUrl.value}`
      copyToClipboard(text)
        .then(() => {
          notiDialog.value = true;
        })
        .catch(() => {
          // fail
        });
    }

    async function handleCameraSwitch() {
      rightPosition.value = false;
      await View.value.switchVideoSource();
    }

    watch(dialog5, () => {
      if (dialog5.value) {
        nextTick(() => startCamera());
      }
    });
    watch(dialog6, () => {
      if (dialog6.value) {
        showIndicator.value = true;
        nextTick(() => startCamera());
      }
    });
    watch(dialog7, () => {
      if (dialog7.value) {
        showIndicator.value = true;
        nextTick(() => startCamera());
      }
    });
    watch(errDialog, () => {
      if (!errDialog.value) {
        // showSwitchBtn.value = true;
        nextBtn.value = "結果を測定する";
        measureDone.value = false;
        nextTick(() => startCamera());
      }
    });

    const scripts = [
      "https://cdn.jsdelivr.net/npm/@tensorflow/tfjs@4.2.0/dist/tf.min.js",
      "https://cdn.jsdelivr.net/npm/@tensorflow-models/blazeface",
      // "/src/face_view.min.js"
      "https://production-glasson.s3.ap-northeast-1.amazonaws.com/hello-o/face_view.min.js"
    ];
    scriptsLoader
      .reset()
      .addScript(scripts)
      .afterLoad()
      .onComplete(() => {
        // console.info("ALL SCRIPTS LOADED");
        isScriptsLoaded.value = true;
        // nextTick(() => startCamera());
      })
      .load();

    return {
      isScriptsLoaded,
      step,
      dialog1,
      dialog2,
      dialog3,
      dialog4,
      dialog5,
      dialog6,
      dialog7,
      dialog8,
      startCamera,
      onNextClick,
      pd,
      faceWidth,
      templeLens,
      camera,
      tryOn,
      inputImage,
      loading,
      getSize,
      nextBtn,
      showIndicator,
      errDialog,
      rightUrl,
      leftUrl,
      frontUrl,
      // leftTempleWidth,
      rightTempleWidth,
      countdown,
      counting,
      startMeasure,
      measuring,
      rightPosition,
      notiDialog,
      isMobile,
      isTablet,
      measureDone,
      showSwitchBtn,
      startCountdown,
      clickCopy,
      clickLink,
      handleCameraSwitch
    };
  }
});
</script>

<template>
  <Layout>
    <template #footer>
      <section
        class="go-functions q-mb-lg"
        style="display: flex; justify-content: center"
      >
        <q-btn
          ref="getSize"
          class="main-btn"
          color="black"
          @click="onNextClick(step)"
          :disable="
            (loading && dialog5) || (loading && dialog6) || (loading && dialog7)
          "
        >
          {{ nextBtn }}
          <i class="arrow-right"></i>
        </q-btn>
      </section>
    </template>

    <q-card v-if="step === 1" class="fit">
      <q-card-section v-if="isMobile" class="helloo-title">
        <h2 class="text-h5 q-mt-none q-mb-sm">HELLOO MY NAME IS...</h2>
        <p class="text-body2 q-my-none">サイズ測定ツール</p>
      </q-card-section>
      <q-card-section v-else class="helloo-title-pc">
        <span class="text-h5 q-mt-none q-mb-sm"
          >HELLOO MY NAME IS
          <span class="text-h6 text-weight-regular"
            >... サイズ測定ツール</span
          ></span
        >
      </q-card-section>
      <div class="helloo-body column justify-center full-width">
        <div v-if="isMobile" class="full-width">
          <q-card-section>
            <div v-if="isTablet" class="text-center sm-text">
              あなたのサイズにフィットするアイウェアを作るため、頭や顔のサイズを測定します。<br />
              お送りした測定メガネをご用意ください。
            </div>
            <div v-else class="text-center sm-text">
              あなたのサイズにフィットするアイウェアを作るた<br />
              め、頭や顔のサイズを測定します。<br />
              お送りした測定メガネをご用意ください。
            </div>
          </q-card-section>
          <q-card flat class="step-card-1">
            <q-card-section class="step-card" horizontal>
              <div class="col-4 step-image">
                <Step1 class="image-width" />
              </div>
              <q-card-section class="col-8">
                <div class="md-text">STEP.1</div>
                <q-separator color="grey-7" />
                <div class="md-text line-height-1">PD・顔幅の測定</div>
                <div class="xs-text" style="font-size: 0.75rem">
                  PD（瞳孔間距離）と顔幅を測定し
                </div>
              </q-card-section>
            </q-card-section>
          </q-card>
          <q-card class="step-card-1">
            <q-card-section class="step-card" horizontal>
              <div class="col-4 step-image">
                <Step2 class="image-width" />
              </div>
              <q-card-section class="col-8">
                <div>STEP.2</div>
                <q-separator color="grey-7" />
                <div class="md-text line-height-1">
                  テンプル長の測定（右側）
                </div>
                <div style="font-size: 0.75rem">
                  耳から目までの距離を測定します。
                </div>
              </q-card-section>
            </q-card-section>
          </q-card>
          <q-card class="step-card-1">
            <q-card-section class="step-card" horizontal>
              <div class="col-4 step-image">
                <Step3 class="image-width" />
              </div>
              <q-card-section class="col-8">
                <div>STEP.3</div>
                <q-separator color="grey-7" />
                <div class="md-text line-height-1">
                  テンプル長の測定（左側）
                </div>
                <div style="font-size: 0.75rem">
                  耳から目までの距離を測定します。
                </div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
        <div v-else class="full-width flex flex-center">
          <q-card-section>
            <div class="text-center sm-text">
              あなたのサイズにフィットするアイウェアを作るため、頭や顔のサイズを測定します。<br />
              お送りした測定メガネをご用意ください。
            </div>
          </q-card-section>
          <div class="flex step-1-width q-mt-xl">
            <q-card class="step-card-1">
              <q-card-section class="step-card" horizontal>
                <div class="step-image">
                  <Step1 class="image-width" />
                </div>
              </q-card-section>
              <q-card-section class="q-mx-md">
                <div class="xs-text">STEP.1</div>
                <q-separator color="grey-7" />
                <div class="xs-text line-height-1">PD・顔幅の測定</div>
                <div class="xxs-text">PD（瞳孔間距離）と顔幅を測定し</div>
              </q-card-section>
            </q-card>
            <q-card class="step-card-1">
              <q-card-section class="step-card" horizontal>
                <div class="step-image">
                  <Step2 class="image-width" />
                </div>
              </q-card-section>
              <q-card-section class="q-mx-md">
                <div class="xs-text">STEP.2</div>
                <q-separator color="grey-7" />
                <div class="xs-text line-height-1">
                  テンプル長の測定（右側）
                </div>
                <div class="xxs-text">耳から目までの距離を測定します。</div>
              </q-card-section>
            </q-card>
            <q-card class="step-card-1">
              <q-card-section class="step-card" horizontal>
                <div class="step-image">
                  <Step3 class="image-width" />
                </div>
              </q-card-section>
              <q-card-section class="q-mx-md">
                <div class="xs-text">STEP.3</div>
                <q-separator color="grey-7" />
                <div class="xs-text line-height-1">
                  テンプル長の測定（左側）
                </div>
                <div class="xxs-text">耳から目までの距離を測定します。</div>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
    </q-card>

    <q-card v-if="step === 2" class="fit">
      <q-card-section :class="isMobile ? 'helloo-title' : 'helloo-title-pc'">
        <span class="text-h5">モデルの適合について</span>
      </q-card-section>
      <div class="helloo-body column justify-center full-width">
        <div class="full-width">
          <q-card-section class="flex-center" style="padding: 5px 0px">
            <NoGlasses class="image-width-2" />
          </q-card-section>
          <q-card-section class="text-center flex flex-center">
            <div class="text-justify sm-text text-weight-regular step-2-width">
              測定の結果やレンズの度数によって、お選び頂いたモデルやカスタマイズが適合しない場合がございます。<br />
              適合しない可能性がある場合、測定後にスタッフより個別にご案内を差し上げますので、何卒ご了承ください。
            </div>
          </q-card-section>
        </div>
      </div>
    </q-card>

    <q-card v-if="step === 3" class="fit">
      <q-card-section :class="isMobile ? 'helloo-title' : 'helloo-title-pc'">
        <span class="text-h5">測定のまえに</span>
      </q-card-section>
      <div class="helloo-body column justify-center full-width">
        <div class="full-width">
          <q-card class="allow-card">
            <q-card-section> 写真へのアクセスを求めています。 </q-card-section>
            <q-separator />
            <q-card-section horizontal style="color: #007aff">
              <q-card-section class="col-6"> 許可しない </q-card-section>
              <q-separator vertical />
              <q-card-section class="col-6"> OK </q-card-section>
              <Hand style="position: absolute; right: 55px; bottom: -40px" />
            </q-card-section>
          </q-card>
          <q-card-section class="text-center flex flex-center">
            <div class="text-justify sm-text text-weight-regular step-2-width">
              お使いのスマートフォンのカメラを使用して、頭や顔
              のサイズを測定します。測定が開始されると、カメラ
              へのアクセス権限を求める案内が表示されますので、
              「許可」や「OK」をご選択ください。
            </div>
          </q-card-section>
        </div>
      </div>
    </q-card>

    <q-card v-if="step === 4" class="fit">
      <q-card-section :class="isMobile ? 'helloo-title' : 'helloo-title-pc'">
        <span class="text-h5">撮影画像の取り扱いについて</span>
      </q-card-section>
      <div class="helloo-body column justify-center full-width">
        <div class="full-width">
          <q-card-section class="flex-center">
            <!-- <q-img 
                style="max-width: 80%" 
                loading="eager"
                fetchpriority="high"
                no-spinner
                src="https://glasson-helloo.s3.ap-northeast-1.amazonaws.com/glasson-measurement-assets/upload.f4473267.png" 
              /> -->
            <Upload class="image-width-2" />
          </q-card-section>
          <q-card-section class="text-center flex flex-center">
            <div class="text-justify sm-text text-weight-regular step-2-width">
              サイズ測定の行程において、お送りした測定用紙メガネをかけた状態で横方向からの撮影を行います。<br />
              撮影した写真データについては、フィッティングの調整作業の参考に使用する場合がございますので、ご注文頂いた商品の保証期間中は
              株式会社 エスケイワイ
              が保管し、保証期間終了とともに消去いたします。
            </div>
          </q-card-section>
        </div>
      </div>
    </q-card>

    <q-card v-if="step === 51" class="fit">
      <q-card-section v-if="isMobile" class="helloo-title">
        <h2 class="text-h5 q-mt-none q-mb-sm">STEP.1</h2>
        <p class="text-body2 q-my-none">PD・顔幅の測定</p>
      </q-card-section>
      <q-card-section v-else class="helloo-title-pc">
        <span class="text-h6 q-mt-none q-mb-sm"
          >STEP.1 <span class="text-h5 q-ml-md">PD・顔幅の測定</span></span
        >
      </q-card-section>
      <div class="helloo-body column justify-center full-width">
        <div class="full-width">
          <q-card-section class="q-pa-none">
            <div v-if="isMobile" class="text-center text-body1 q-mb-sm">
              測定メガネを着用して、<br
                v-if="!isTablet"
              />正面を向いてください。
            </div>
            <div v-else class="text-center text-body1 q-mb-sm">
              測定メガネを着用して、正面を向いてください。
            </div>
            <div class="full-width">
              <Measure1 class="measure-image" />
            </div>
            <div class="flex flex-center text-center text-body1 q-mt-sm">
              <q-btn class="guide-btn" color="black" label="測定する" />
              <span v-if="isMobile && !isTablet" class="q-ml-sm">
                を押すと、
              </span>
              <span v-if="isMobile && isTablet" class="q-ml-sm">
                を押すと、3回のカウントダウン音の後に、
              </span>
              <span v-if="!isMobile" class="q-ml-sm">
                を押すと、3回のカウントダウン音の後に、シャッター音が鳴ります。※マナーモード解除を推奨
              </span>
            </div>
            <div
              v-if="isMobile && isTablet"
              class="text-center text-body1 q-mb-sm"
            >
              シャッター音が鳴ります。※マナーモード解除を推奨
            </div>
            <div
              v-if="isMobile && !isTablet"
              class="text-center text-body1 q-mb-sm"
            >
              3回のカウントダウン音の後に、<br />
              シャッター音が鳴ります。<br />
              <span class="text-body2">※マナーモード解除を推奨</span>
            </div>
          </q-card-section>
        </div>
      </div>
    </q-card>
    <q-card v-show="step === 5" class="fit">
      <q-card-section v-if="isMobile" class="helloo-title">
        <h2 class="text-h5 q-mt-none q-mb-sm">STEP.1</h2>
        <p class="text-body2 q-my-none">PD・顔幅の測定</p>
      </q-card-section>
      <q-card-section v-else class="helloo-title-pc">
        <span class="text-h6 q-mt-none q-mb-sm"
          >STEP.1 <span class="text-h5 q-ml-md">PD・顔幅の測定</span></span
        >
      </q-card-section>
      <div class="helloo-body column justify-center full-width">
        <div class="full-width camera-pc">
          <q-card-section class="q-pa-none">
            <!-- <div class="text-center text-body1 q-mb-sm">
                測定メガネを着用して、<br/>
                正面を向いてください。
              </div> -->
            <div
              v-if="isScriptsLoaded && dialog5"
              ref="camera"
              style="width: 100%; height: 100%"
            >
              <div class="oval">
                <RedLine v-show="startMeasure && !rightPosition" />
                <GreenLine v-show="startMeasure && rightPosition" />
                <div
                  v-if="counting"
                  class="countdown"
                  :class="counting ? 'animation' : ''"
                >
                  {{ countdown }}
                </div>
              </div>
              <q-inner-loading :showing="showIndicator" class="absolute z-top">
                <q-spinner-ios size="50px" color="black" />
              </q-inner-loading>
              <canvas id="tryon_canvas" ref="tryOn" />
              <video id="video" playsinline style="display: none" />
              <div
                v-if="(isMobile || isTablet) && !showIndicator && showSwitchBtn"
                style="
                  position: absolute;
                  z-index: 9999;
                  bottom: 60px;
                  right: 30px;
                  float: right;
                "
                @click="handleCameraSwitch"
              >
                <q-avatar color="black" size="35px">
                  <SwitchCamera />
                </q-avatar>
                <p
                  class="text-shadow absolute"
                  style="
                    width: 200px;
                    bottom: -50px;
                    font-size: 12px;
                    color: white;
                  "
                >
                  カメラ<br />切替え
                </p>
              </div>
            </div>
            <!-- <div class="text-center text-body1 q-mt-xs">シャッター音が鳴れば、次に進みます。</div> -->
          </q-card-section>
        </div>
      </div>
    </q-card>

    <q-card v-if="step === 61" class="fit">
      <q-card-section v-if="isMobile" class="helloo-title">
        <h2 class="text-h5 q-mt-none q-mb-sm">STEP.2</h2>
        <p class="text-body2 q-my-none">テンプル長の測定（右側）</p>
      </q-card-section>
      <q-card-section v-else class="helloo-title-pc">
        <span class="text-h6 q-mt-none q-mb-sm"
          >STEP.2
          <span class="text-h5 q-ml-md">テンプル長の測定（右側）</span></span
        >
      </q-card-section>
      <div class="helloo-body column justify-center full-width">
        <div class="full-width">
          <q-card-section class="q-pa-none">
            <div v-if="isMobile" class="text-center text-body1 q-mb-sm">
              測定メガネを着用して、<br v-if="!isTablet" /><span class="text-h5"
                >左</span
              >を向いてください。
            </div>
            <div v-else class="text-center text-body1 q-mb-sm">
              測定メガネを着用して、<span class="text-h5">左</span
              >を向いてください。
            </div>
            <div class="full-width">
              <Measure2 class="measure-image" />
            </div>
            <div class="flex flex-center text-center text-body1 q-mt-sm">
              <q-btn class="guide-btn" color="black" label="測定する" />
              <span v-if="isMobile && !isTablet" class="q-ml-sm">
                を押すと、
              </span>
              <span v-if="isMobile && isTablet" class="q-ml-sm">
                を押すと、3回のカウントダウン音の後に、
              </span>
              <span v-if="!isMobile" class="q-ml-sm">
                を押すと、3回のカウントダウン音の後に、シャッター音が鳴ります。※マナーモード解除を推奨
              </span>
            </div>
            <div
              v-if="isMobile && isTablet"
              class="text-center text-body1 q-mb-sm"
            >
              シャッター音が鳴ります。※マナーモード解除を推奨
            </div>
            <div
              v-if="isMobile && !isTablet"
              class="text-center text-body1 q-mb-sm"
            >
              3回のカウントダウン音の後に、<br />
              シャッター音が鳴ります。<br />
              <span class="text-body2">※マナーモード解除を推奨</span>
            </div>
          </q-card-section>
        </div>
      </div>
    </q-card>
    <q-card v-show="step === 6" class="fit">
      <q-card-section v-if="isMobile" class="helloo-title">
        <h2 class="text-h5 q-mt-none q-mb-sm">STEP.2</h2>
        <p class="text-body2 q-my-none">テンプル長の測定（右側）</p>
      </q-card-section>
      <q-card-section v-else class="helloo-title-pc">
        <span class="text-h6 q-mt-none q-mb-sm"
          >STEP.2
          <span class="text-h5 q-ml-md">テンプル長の測定（右側）</span></span
        >
      </q-card-section>
      <div class="helloo-body column justify-center full-width">
        <div class="full-width camera-pc">
          <q-card-section class="q-pa-none">
            <!-- <div class="text-center text-body1 q-mb-xs">
                測定メガネを着用して、<br/>
                <span class="text-h5">左</span>を向いてください。
              </div> -->
            <div
              v-if="isScriptsLoaded && dialog6"
              ref="camera"
              style="width: 100%; height: 100%"
            >
              <div class="oval">
                <RedLine v-show="startMeasure && !rightPosition" />
                <GreenLine v-show="startMeasure && rightPosition" />
                <div
                  v-if="counting"
                  class="countdown"
                  :class="counting ? 'animation' : ''"
                >
                  {{ countdown }}
                </div>
              </div>
              <q-inner-loading :showing="showIndicator" class="absolute z-top">
                <q-spinner-ios size="50px" color="black" />
              </q-inner-loading>
              <canvas
                id="tryon_canvas"
                ref="tryOn"
                style="width: 100%; height: 100%"
              />
              <video id="video" playsinline style="display: none" />
              <div
                v-if="(isMobile || isTablet) && !showIndicator && showSwitchBtn"
                style="
                  position: absolute;
                  z-index: 9999;
                  bottom: 60px;
                  right: 30px;
                  float: right;
                "
                @click="handleCameraSwitch"
              >
                <q-avatar color="black" size="35px">
                  <SwitchCamera />
                </q-avatar>
                <p
                  class="text-shadow absolute"
                  style="
                    width: 200px;
                    bottom: -50px;
                    font-size: 12px;
                    color: white;
                  "
                >
                  カメラ<br />切替え
                </p>
              </div>
            </div>
            <!-- <div class="text-center text-body1">シャッター音が鳴れば、次に進みます。</div> -->
          </q-card-section>
        </div>
      </div>
    </q-card>

    <q-card v-if="step === 71" class="fit">
      <q-card-section v-if="isMobile" class="helloo-title">
        <h2 class="text-h5 q-mt-none q-mb-sm">STEP.3</h2>
        <p class="text-body2 q-my-none">テンプル長の測定（左側）</p>
      </q-card-section>
      <q-card-section v-else class="helloo-title-pc">
        <span class="text-h6 q-mt-none q-mb-sm"
          >STEP.3
          <span class="text-h5 q-ml-md">テンプル長の測定（左側）</span></span
        >
      </q-card-section>
      <div class="helloo-body column justify-center full-width">
        <div class="full-width">
          <q-card-section class="q-pa-none">
            <div v-if="isMobile" class="text-center text-body1 q-mb-sm">
              測定メガネを着用して、<br v-if="!isTablet" /><span class="text-h5"
                >右</span
              >を向いてください。
            </div>
            <div v-else class="text-center text-body1 q-mb-sm">
              測定メガネを着用して、<span class="text-h5">右</span
              >を向いてください。
            </div>
            <div class="full-width">
              <Measure3 class="measure-image" />
            </div>
            <div class="flex flex-center text-center text-body1 q-mt-sm">
              <q-btn class="guide-btn" color="black" label="測定する" />
              <span v-if="isMobile && !isTablet" class="q-ml-sm">
                を押すと、
              </span>
              <span v-if="isMobile && isTablet" class="q-ml-sm">
                を押すと、3回のカウントダウン音の後に、
              </span>
              <span v-if="!isMobile" class="q-ml-sm">
                を押すと、3回のカウントダウン音の後に、シャッター音が鳴ります。※マナーモード解除を推奨
              </span>
            </div>
            <div
              v-if="isMobile && isTablet"
              class="text-center text-body1 q-mb-sm"
            >
              シャッター音が鳴ります。※マナーモード解除を推奨
            </div>
            <div
              v-if="isMobile && !isTablet"
              class="text-center text-body1 q-mb-sm"
            >
              3回のカウントダウン音の後に、<br />
              シャッター音が鳴ります。<br />
              <span class="text-body2">※マナーモード解除を推奨</span>
            </div>
          </q-card-section>
        </div>
      </div>
    </q-card>
    <q-card v-show="step === 7" class="fit">
      <q-card-section v-if="isMobile" class="helloo-title">
        <h2 class="text-h5 q-mt-none q-mb-sm">STEP.3</h2>
        <p class="text-body2 q-my-none">テンプル長の測定（左側）</p>
      </q-card-section>
      <q-card-section v-else class="helloo-title-pc">
        <span class="text-h6 q-mt-none q-mb-sm"
          >STEP.3
          <span class="text-h5 q-ml-md">テンプル長の測定（左側）</span></span
        >
      </q-card-section>
      <div class="helloo-body column justify-center full-width">
        <div class="full-width camera-pc">
          <q-card-section class="q-pa-none">
            <!-- <div class="text-center text-body1 q-mb-xs">
                測定メガネを着用して、<br/>
                <span class="text-h5">右</span>を向いてください
              </div> -->
            <div
              v-if="isScriptsLoaded && dialog7"
              ref="camera"
              style="width: 100%; height: 100%"
            >
              <div class="oval">
                <RedLine v-show="startMeasure && !rightPosition" />
                <GreenLine v-show="startMeasure && rightPosition" />
                <div
                  v-if="counting"
                  class="countdown"
                  :class="counting ? 'animation' : ''"
                >
                  {{ countdown }}
                </div>
              </div>
              <q-inner-loading :showing="showIndicator" class="absolute z-top">
                <q-spinner-ios size="50px" color="black" />
              </q-inner-loading>
              <canvas id="tryon_canvas" ref="tryOn" />
              <video id="video" playsinline style="display: none" />
              <div
                v-if="(isMobile || isTablet) && !showIndicator && showSwitchBtn"
                style="
                  position: absolute;
                  z-index: 9999;
                  bottom: 60px;
                  right: 30px;
                  float: right;
                "
                @click="handleCameraSwitch"
              >
                <q-avatar color="black" size="35px">
                  <SwitchCamera />
                </q-avatar>
                <p
                  class="text-shadow absolute"
                  style="
                    width: 200px;
                    bottom: -50px;
                    font-size: 12px;
                    color: white;
                  "
                >
                  カメラ<br />切替え
                </p>
              </div>
            </div>
            <!-- <div class="text-center text-body1">シャッター音が鳴れば、測定完了です。</div> -->
          </q-card-section>
        </div>
      </div>
    </q-card>

    <q-card v-if="step === 8" class="fit">
      <q-card-section class="helloo-title-pc">
        <span class="text-h5">測定結果</span>
      </q-card-section>
      <div class="helloo-body column justify-center full-width q-pb-xl">
        <div class="full-width flex flex-center">
          <q-card-section class="step-8-card">
            <q-list dense class="text-step-7">
              <q-item>
                <q-item-section>
                  <q-item-label>PD</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label style="color: black"> {{ pd }}mm </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator color="grey-7" spaced inset size="2px" />

              <q-item>
                <q-item-section>
                  <q-item-label>顔幅</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label style="color: black">
                    {{ faceWidth }}mm
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator color="grey-7" spaced inset size="2px" />

              <q-item>
                <q-item-section>
                  <q-item-label>左テンプル長</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label style="color: black">
                    {{ templeLens }}mm
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-separator color="grey-7" spaced inset size="2px" />

              <q-item>
                <q-item-section>
                  <q-item-label>右テンプル長</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-item-label style="color: black">
                    {{ rightTempleWidth }}mm
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>

            <!-- temporary -->
            <q-list dense>
              <q-separator color="grey-7" spaced inset size="2px" />
              <q-item>
                <q-item-section>顔幅画像</q-item-section>
                <q-item-section side>
                  <q-icon
                    class="cursor-pointer"
                    @click="clickLink('front')"
                    color="black"
                    name="file_upload"
                  />
                </q-item-section>
              </q-item>
              <q-separator color="grey-7" spaced inset size="2px" />
              <q-item>
                <q-item-section>左テンプル画像</q-item-section>
                <q-item-section side>
                  <q-icon
                    class="cursor-pointer"
                    @click="clickLink('left')"
                    color="black"
                    name="file_upload"
                  />
                </q-item-section>
              </q-item>
              <q-separator color="grey-7" spaced inset size="2px" />
              <q-item>
                <q-item-section>右テンプル画像</q-item-section>
                <q-item-section side>
                  <q-icon
                    class="cursor-pointer"
                    @click="clickLink('right')"
                    color="black"
                    name="file_upload"
                  />
                </q-item-section>
              </q-item>
            </q-list>

            <q-card-section class="text-center q-mt-sm margin-step-8">
              測定にご協力いただき、<br />
              ありがとうございました。
            </q-card-section>
          </q-card-section>
          <!-- <q-card-section>
              <div class="text-center text-body1">
                測定にご協力いただき、<br />
                ありがとうございました。
              </div>
            </q-card-section> -->
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <q-btn
          v-if="step === 8"
          ref="getSize"
          class="copy-btn"
          color="black"
          @click="clickCopy()"
        >
          計測数値をコピーする
          <i class="arrow-right"></i>
        </q-btn>
      </div>
    </q-card>

    <GDialog
      transition="custom-from-right-transition"
      v-model="errDialog"
      local
    >
      <q-card style="height: 100%">
        <q-card-section class="q-pb-none">
          <pre class="text-center small-text" style="white-space: pre-line">
            測定できませんでした。
            測定メガネを着用して、やり直してください。
          </pre>
        </q-card-section>
        <q-card-action
          style="display: flex; justify-content: center; align-items: center"
        >
          <q-btn
            @click="errDialog = false"
            color="black"
            label="OK"
            text-color="white"
            style="margin-bottom: 10px !important"
            unelevated
            rounded
          />
        </q-card-action>
      </q-card>
    </GDialog>
    <GDialog v-model="notiDialog" local>
      <q-card style="height: 100%">
        <q-card-section class="q-pb-none">
          <pre class="text-center small-text" style="white-space: pre-line">
            クリップボードにコピーされました。
          </pre>
        </q-card-section>
        <q-card-action
          style="display: flex; justify-content: center; align-items: center"
        >
          <q-btn
            @click="notiDialog = false"
            color="black"
            label="OK"
            text-color="white"
            style="margin-bottom: 10px !important"
            unelevated
            rounded
          />
        </q-card-action>
      </q-card>
    </GDialog>
  </Layout>
</template>

<style lang="scss">
@import "assets/scss/app.scss";

@keyframes count {
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
  0% {
    opacity: 1;
    transform: scale(1.5);
  }
}
.countdown {
  width: 20px;
  position: relative;
  bottom: 5%;
  left: 45%;
  z-index: 9999;
  color: #66cc66;
  font-size: 2.5rem;
}
.animation {
  animation: count 1.5s infinite;
}
</style>
