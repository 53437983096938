<template>
  <div>
    <q-toolbar class="bg-primary text-white shadow-2">
      <q-toolbar-title>Favorite List</q-toolbar-title>
    </q-toolbar>
    <q-scroll-area style="height: 400px; width: 100%;">
      <div class="q-pa-sm">
        Input data
      </div>
      <div class="q-pa-sm">
        <q-item-section>
          <!-- <p>item {{ index + 1 }}</p> -->
          <pre>{{ inputData }}</pre>
        </q-item-section>
        <q-separator />
      </div>
    </q-scroll-area>
  </div>
</template>


<script>
import { defineComponent, ref } from 'vue'
import { useEnhancer } from '@/enhancer'
import { unProxify } from '@/transforms/object'

export default defineComponent({
  name: 'FavoriteList',
  setup() {
    const { storeFavorites, storePrice } = useEnhancer()
    let inputData = ref({})
    storeFavorites.$subscribe(() => {
      inputData.value.favoriteList = unProxify(storeFavorites.favorites)
    })
    storePrice.$subscribe(() => {
      inputData.value.variantPrice = unProxify(storePrice.variantPrice)
    })
    function selected(id) {
      storeFavorites.setSelected(id)
    }
    return {
      inputData,
      storeFavorites,
      storePrice,
      selected
    }
  },
})
</script>
